import "./style.less";
import Modal from "antd/lib/modal";
import { useTranslation } from "react-i18next";
import { Space, Typography } from "antd";
import React from "react";
import VuiButton from "../../Button";

const { Text, Paragraph } = Typography;

interface Props {
  show: boolean;
  onCancel: () => void;
  image?: () => React.ReactChild;
  headerTitle?: string;
  title?: string;
  subtitle?: string;
  btnTitle?: string;
  info?: string;
}

const VuiModalAlert: React.FC<Props> = ({
  show,
  onCancel,
  headerTitle,
  title,
  subtitle,
  btnTitle,
  image,
  info,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="vui-modal-alert"
      title={headerTitle}
      centered
      visible={show}
      onCancel={onCancel}
      footer={null}
    >
      <Space size={16} direction="vertical">
        <div className="image-wrapper">{image ? image() : null}</div>
        <div className="text-wrapper">
          <Paragraph strong className="title">
            {title ? title : t("modal.confirmation.title")}
          </Paragraph>
          <Text>{subtitle ? subtitle : t("modal.confirmation.subtitle")}</Text>

          {info && <Paragraph className="info">{info}</Paragraph>}
        </div>

        <VuiButton
          buttonProps={{
            type: "primary",
            onClick: onCancel,
            size: "small",
          }}
          label={btnTitle ? btnTitle : t("common.button.back")}
        />
      </Space>
    </Modal>
  );
};

export default VuiModalAlert;
