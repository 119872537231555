import "./_style.less";
import React from "react";
import Button, { ButtonProps } from "antd/es/button";
import Spin from "antd/lib/spin";
import clsx from "clsx";

interface IVuiButton {
  loading?: boolean;
  label: string;
  buttonProps?: ButtonProps;
  isFitContent?: boolean;
}

const VuiButton = ({
  loading = false,
  buttonProps = {},
  label = "",
  isFitContent = false,
}: IVuiButton) => {
  const {
    htmlType = "button",
    className = "vui-button",
    block = true,
    ...other
  } = buttonProps;

  return (
    <Spin
      spinning={loading}
      wrapperClassName={clsx({
        "w-fit-content": isFitContent,
      })}
    >
      <Button
        className={className}
        htmlType={htmlType}
        block={block}
        {...other}
      >
        {label}
      </Button>
    </Spin>
  );
};

export default VuiButton;
