import { Navigate } from "react-router-dom";
import GuestLayout from "../layouts/Guest";
import Redeem from "../pages/Guest/Redeem";

const guestRoutes = [
  {
    path: "/",
    element: <GuestLayout />,
    children: [
      { path: "/", element: <Navigate to={"/redeem"} /> },
      { path: "/redeem", element: <Redeem /> },
    ],
  },
];

export default guestRoutes;
