import { useState } from "@hookstate/core";
import { Col, Row } from "antd";
import Button from "antd/es/button";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Typography from "antd/es/typography";
import Spin from "antd/lib/spin";
import { AxiosError, AxiosResponse } from "axios";
import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import VuiModalAlert from "../../../@vodea/vodea-ui/components/Modal/Alert";
import { handleBackendError } from "../../../functions/global";
import RedeemRepository from "../../../repositories/RedeemRepository";

const { Text, Title } = Typography;

const Redeem: React.FC<any> = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const loading = useState(false);

  //modal
  const [redeemedProduct, setRedeemedProduct] = React.useState<string | null>(
    null
  );

  const onFinish = (data: any) => {
    loading.set(true);

    RedeemRepository.redeem(data)
      .then((res: AxiosResponse) => {
        const { data: redeemProduct } = res.data;
        loading.set(false);
        setRedeemedProduct(redeemProduct.product_name);
      })
      .catch((e: AxiosError) => {
        handleBackendError(e, t("notification.error.default"));
        loading.set(false);
      });
  };

  const handleCloseModal = () => {
    setRedeemedProduct(null);
    form.resetFields();
  };

  return (
    <>
      <Helmet>
        <title>{t("guest.redeem.metaTitle")}</title>
      </Helmet>

      <Row gutter={[24, 24]}>
        <Col className="content" span={12} xs={24} md={12}>
          <img
            className="illustration"
            src={"/images/redeem-banner.png"}
            alt="banner"
          />
        </Col>
        <Col className="content" span={12} xs={24} md={12}>
          <div className="form-wrapper">
            <div className="mb24">
              <Title level={3} className="title">
                {t("guest.redeem.title")} <span>Reward</span>
              </Title>
              <Text>{t("guest.redeem.subtitle")}</Text>
            </div>
            <div>
              <Form form={form} layout={"vertical"} onFinish={onFinish}>
                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      name="name"
                      label={t("common.form.name.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.name.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("common.form.name.placeholder")}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      name="phone_number"
                      label={t("common.form.phoneNumber.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.phoneNumber.label"),
                          }),
                        },
                        {
                          pattern: new RegExp("^[0-9]*$"),
                          message: t("validation.type.number", {
                            item: t("common.form.phoneNumber.label"),
                          }),
                        },
                        /* {
                          min: 10,
                          message: t("validation.type.min", {
                            item: t("common.form.phoneNumber.label"),
                            min: 10,
                          }),
                        },
                        {
                          max: 14,
                          message: t("validation.type.max", {
                            item: t("common.form.phoneNumber.label"),
                            max: 14,
                          }),
                        }, */
                      ]}
                    >
                      <Input
                        size={"large"}
                        placeholder={t("common.text.input", {
                          item: t("common.form.phoneNumber.label"),
                        })}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      name="email"
                      label={t("common.form.email.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.email.label"),
                          }),
                        },
                        {
                          type: "email",
                          message: t("validation.type.email", {
                            item: t("common.form.email.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("common.form.email.placeholder")}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </div>

                <div className="vui-form-group type-column">
                  <div className="input-section">
                    <Form.Item
                      name="code"
                      label={t("common.form.uniqueCode.label")}
                      rules={[
                        {
                          required: true,
                          message: t("validation.required", {
                            item: t("common.form.uniqueCode.label"),
                          }),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("common.form.uniqueCode.placeholder")}
                        size={"large"}
                      />
                    </Form.Item>
                  </div>
                </div>

                <Spin spinning={loading.get()}>
                  <Button htmlType="submit" type="primary" block size={"large"}>
                    {t("common.button.redeemGift")}
                  </Button>
                </Spin>
              </Form>
            </div>
          </div>
        </Col>
      </Row>

      <VuiModalAlert
        show={Boolean(redeemedProduct)}
        image={() => (
          <img src={"/images/reward-redeem-banner.png"} alt="redeem-banner" />
        )}
        headerTitle={t("modal.alert.rewardRedeem.headerTitle")}
        title={t("modal.alert.rewardRedeem.title")}
        subtitle={t("modal.alert.rewardRedeem.subtitle", {
          item: redeemedProduct,
        })}
        onCancel={handleCloseModal}
      />
    </>
  );
};

export default Redeem;
