import { Outlet } from "react-router";
import { Layout, Typography } from "antd";
import { env } from "../../constant";

const { Header, Content, Footer } = Layout;
const { Text } = Typography;

const ErrorLayout: React.FC<any> = () => {
  return (
    <section id={"auth-layout"}>
      <Layout className="container-wrapper">
        <Header className="header">
          <Text className="text-logo">
            <b>{env.getAppName()}</b>
          </Text>
        </Header>
        <Content className="content-wrapper">
          <Outlet />
        </Content>
        <Footer className="footer">© 2022 {env.getAppName()}</Footer>
      </Layout>
    </section>
  );
};

export default ErrorLayout;
