import { api, createCancelTokenHandler } from "../@vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  redeem: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/landing-page/redeem`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.redeem.name].handleRequestCancellation()
          .token,
    });
  },
  redeemLoyalty: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/landing-page/redeem/loyalty`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.redeemLoyalty.name
        ].handleRequestCancellation().token,
    });
  },
  redeemGift: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/landing-page/redeem/gift`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.redeemGift.name
        ].handleRequestCancellation().token,
    });
  },
};

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);

export default Repository;
