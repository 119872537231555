import {Image, Layout, Typography} from "antd";
import { Outlet } from "react-router";
import { env } from "../../constant";

const { Text } = Typography;

const { Header, Content, Footer } = Layout;

const GuestLayout: React.FC<any> = () => {
  return (
    <section id={"auth-layout"}>
      <Layout className="container-wrapper">
        <Header className="header">
          <Image src={"images/logo-text.png"} width={90} preview={false} />
        </Header>
        <Content className="content-wrapper">
          <Outlet />
        </Content>
        <Footer className="footer">© 2022 Bukado</Footer>
      </Layout>
    </section>
  );
};

export default GuestLayout;
